/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BillingAddress } from "./BillingAddress";

export type Recipient = {
  first_name: string;
  last_name: string;
  date_of_birth: string;
  currency: Recipient.currency;
  billing_address: BillingAddress;
};

export namespace Recipient {
  export enum currency {
    USD = "USD",
    GBP = "GBP",
  }
}

import { defineAsyncComponent } from "vue";
import { createRouter, createWebHistory } from "vue-router";
import LoadingSpinner from "@two-ui/components/LoadingSpinner.vue";
import ErrorPage from "./pages/Error/index.vue";
import { useAuth0 } from "@auth0/auth0-vue";
import { Root } from "./pages";
import RootSpend from "./pages/Spend/root.vue";
import { usePayoutGlobalStore } from "./global-store";

import { RouteNames } from "./utils/routes";

export const routes = [
  {
    path: "/:token",
    component: Root,
    children: [
      {
        path: "onboarding",
        name: RouteNames.Onboarding,
        component: async () =>
          defineAsyncComponent({
            loader: () => import("./pages/Onboarding/index.vue"),
            errorComponent: ErrorPage,
          }),
      },
      {
        path: "home",
        name: RouteNames.Home,
        component: async () =>
          defineAsyncComponent({
            loader: () => import("./pages/Home/index.vue"),
            errorComponent: ErrorPage,
          }),
      },
      {
        path: "assets/all",
        name: RouteNames.Assets,
        component: async () =>
          defineAsyncComponent({
            loader: () => import("./pages/Assets/index.vue"),
            errorComponent: ErrorPage,
          }),
      },
      {
        path: "assets/:assetToken",
        name: RouteNames.Spend,
        component: async () =>
          defineAsyncComponent({
            loader: () => import("./pages/Spend/index.vue"),
            errorComponent: ErrorPage,
          }),
      },
    ],
  },
  {
    path: "/-/:assetToken",
    component: RootSpend,
    children: [
      {
        path: "",
        name: RouteNames.NewSpend,
        component: async () =>
          defineAsyncComponent({
            loader: () => import("./pages/Spend/index.vue"),
            errorComponent: ErrorPage,
          }),
      },
      {
        path: "recipient",
        name: "recipient-details",
        meta: { requiresAuth: true, requiresRedemptionData: true },
        component: async () =>
          defineAsyncComponent({
            loader: () =>
              import("../../recipient/src/views/RecipientDetails.vue"),
            loadingComponent: LoadingSpinner,
          }),
      },
      {
        path: "recipient/edit-details",
        name: "recipient-edit-details",
        meta: { requiresAuth: true, requiresRedemptionData: true },
        component: async () =>
          defineAsyncComponent({
            loader: () =>
              import("../../recipient/src/views/EditRecipientDetails.vue"),
            loadingComponent: LoadingSpinner,
          }),
      },
      {
        path: "recipient/card",
        name: "recipient-card",
        meta: { requiresAuth: true, requiresRedemptionData: true },
        component: async () =>
          defineAsyncComponent({
            loader: () =>
              import("../../recipient/src/views/RecipientCardDetails.vue"),
            loadingComponent: LoadingSpinner,
          }),
      },
      {
        path: "recipient/logout",
        name: "recipient-logout",
        meta: { requiresAuth: false },
        component: async () =>
          defineAsyncComponent({
            loader: () =>
              import("../../recipient/src/views/RecipientLogout.vue"),
            loadingComponent: LoadingSpinner,
          }),
      },
    ],
  },
  {
    path: "/error",
    name: RouteNames.Error,
    component: ErrorPage,
  },
];

export const router = createRouter({
  history: createWebHistory(),
  scrollBehavior(to, from, savedPosition) {
    if (from.meta.scrollTopOnLeave || to.meta.scrollTopOnEnter) {
      return { top: 0, left: 0 };
    }
    return savedPosition || undefined;
  },
  routes: routes,
});

router.beforeEach(async (to, from, next) => {
  const { loginWithRedirect, isAuthenticated } = useAuth0();
  const store = usePayoutGlobalStore();
  if (to.meta.requiresAuth && !isAuthenticated.value) {
    loginWithRedirect({
      appState: {
        target: to.fullPath,
      },
    });
  }

  if (to.meta.requiresRedemptionData && to.params.assetToken) {
    try {
      await store.ensureRedemptionData(to.params.assetToken.toString());
    } catch (error) {
      console.error("Failed to load redemption data:", error);
      return next({
        name: RouteNames.Error,
        query: { message: "Failed to load redemption details" },
      });
    }
  }

  next();
});

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type BillingAddress = {
  address_line_1: string;
  address_line_2?: string;
  city: string;
  state_province: string;
  country: BillingAddress.country;
  postal_code: string;
};

export namespace BillingAddress {
  export enum country {
    US = "US",
    GB = "GB",
  }
}

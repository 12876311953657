<script setup lang="ts">
import { OpenAPI } from "./api.generated/scion";
import { OpenAPI as SpendOpenAPI } from "@payout-link/api.generated/scion";
import { onMounted } from "vue";
import { OpenAPI as RecipientOpenAPI } from "@workspace/open-api/recipient-service";
import { OpenAPI as PayToCardOpenAPI } from "@workspace/open-api/paytocard-service";
OpenAPI.BASE = (import.meta.env.VITE_SCION_BASE_URL as string).split(",")[0];
SpendOpenAPI.BASE = (import.meta.env.VITE_SCION_BASE_URL as string).split(
  ","
)[0];
// Load application fonts
import "@fontsource/inter/300.css";
import "@fontsource/inter/400.css";
import "@fontsource/inter/500.css";
import "@fontsource/inter/600.css";
import "@fontsource/inter/700.css";
import "@fontsource/inter/800.css";
import "@wegift/company-brand-assets/dist/design-system/css/font-f37Lineca.css";

const baseUrl = import.meta.env.VITE_UI_API_GATEWAY_BASE_URL as string;
RecipientOpenAPI.BASE = baseUrl;
PayToCardOpenAPI.BASE = baseUrl;

onMounted(() => {
  document.documentElement.style.setProperty("--bg-color-spend", "#F3F3F3");
});
</script>

<template>
  <router-view />
</template>

<style scoped></style>
